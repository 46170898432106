import React, { useState, useRef } from 'react';

import Chevron from '@/assets/svg/chevron.svg';
import classNames from 'classnames';

interface AccordionProps {
  title: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');

  const ref = useRef(null);

  function toggleAccordion() {
    setActive((e) => !e);
    setHeight(active ? '0px' : `${ref.current.scrollHeight}px`);
  }

  return (
    <div className="my-[30px] lg:my-[60px] text-base lg:text-2xl">
      <button
        className="flex items-center font-bold outline-none"
        type="button"
        onClick={toggleAccordion}
      >
        <Chevron
          className={classNames(
            'fill-primary transition-transform duration-500  min-w-[35px]',
            {
              '-rotate-180': active,
            },
          )}
          width={32}
        />
        <p className="ml-[20px] text-left">{title}</p>
      </button>
      <div
        className="overflow-hidden transition-all duration-500"
        ref={ref}
        style={{ maxHeight: height }}
      >
        <p className="pt-[20px] lg:pt-[40px] subcontent lg:pl-[52px]">
          {children}
        </p>
      </div>
    </div>
  );
};

export default Accordion;
