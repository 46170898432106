import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { tradWithColors } from '@/utils/trad';

import Hero from '@/components/Hero';
import {
  SERVICE_UXUI,
  SERVICE_DATA,
  SERVICE_GROWTH,
  SERVICE_TRANSITION,
  SERVICE_DEV,
} from '@/referentials/routes';

const ServicesHero = () => {
  const { t } = useTranslation();

  const tags = useMemo(
    () => [
      {
        name: t('devTitle'),
        image: 'tools',
        bg: 'bg-yellow',
        blobFirstColor: '#F0C798',
        blobSecondColor: '#FED29F',
        slug: SERVICE_DEV,
      },
      {
        name: t('uiuxTitle'),
        image: 'paint',
        bg: 'bg-primaryLighter',
        blobFirstColor: '#AEA2D3',
        blobSecondColor: '#C8BAF4',
        slug: SERVICE_UXUI,
      },
      {
        name: t('dataTitle'),
        image: 'science',
        bg: 'bg-green',
        blobFirstColor: '#CEE4D6',
        blobSecondColor: '#D5ECDD',
        slug: SERVICE_DATA,
      },
      {
        name: t('growthTitle'),
        image: 'growth',
        bg: 'bg-orange',
        blobFirstColor: '#F0BDB5',
        blobSecondColor: '#FECBC3',
        slug: SERVICE_GROWTH,
      },
      {
        name: t('digitalTitle'),
        image: 'brief',
        bg: 'bg-greenLighter',
        blobFirstColor: '#D1D4E9',
        blobSecondColor: '#E2E5F9',
        slug: SERVICE_TRANSITION,
      },
    ],
    [t],
  );

  const htmlContent = useMemo(() => tradWithColors(t('servicesHeroTitle')), []);

  const [hovering, setHovering] = useState(false);
  const [hovered, setHovered] = useState(0);

  const data = useStaticQuery(graphql`
    query {
      rocket: file(relativePath: { eq: "rocket.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      tools: file(relativePath: { eq: "dev.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      paint: file(relativePath: { eq: "ux-ui.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      growth: file(relativePath: { eq: "growth-hacking.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      science: file(relativePath: { eq: "data-science.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      brief: file(relativePath: { eq: "transition.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <Hero
      fullHeight
      title={htmlContent}
      subTitle={<div className="lg:w-2/3">{t('servicesHeroSubTitle')}</div>}
      image={{
        image: getImage(!hovering ? data.rocket : data[tags[hovered].image]), // rajouter rocket par defaut
        alt: `${tags[hovered].name} icon`,
      }}
      blobFirstColor={hovering && tags[hovered].blobFirstColor}
      blobSecondColor={hovering && tags[hovered].blobSecondColor}
      classNameImg="rotate-[30deg]"
    >
      <div className="flex gap-5 flex-wrap mt-[50px] xl:mt-[100px]">
        {tags.map(({ name, bg, slug }, i) => (
          <Link to={slug}>
            <div
              role="none"
              key={i}
              className={classNames(
                'p-4 2xl:px-12 2xl:py-6 bg-green-900 font-heading text-lg 2xl:text-3xl text-mainDark rounded-full cursor-pointer hover:shadow-csm hover:-translate-y-[3%] transition-all duration-500',
                bg,
              )}
              onMouseEnter={() => {
                setHovered(i);
                setHovering(true);
              }}
              onMouseLeave={() => setHovering(false)}
            >
              {name}
            </div>
          </Link>
        ))}
      </div>
    </Hero>
  );
};

export default ServicesHero;
