import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { getImage } from 'gatsby-plugin-image';

import { CONTACT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import ServicesHero from '@/components/Services/ServicesHero';
import Questions from '@/components/Services/Questions';
import CtaBlock from '@/components/CtaBlock';

export default function Services({ data }) {
  const { t } = useTranslation();
  return (
    <Layout
      title="Services"
      description="Le charrette.clib design, code et fait grandir des produits digitaux innovants."
    >
      <ServicesHero />
      <Questions className="my-[200px]" />
      <CtaBlock
        title={t('ctaBlockTitle')}
        to={CONTACT}
        linkTitle={t('ctaBlockLink')}
        emoji={{
          image: getImage(data.puzzle),
          alt: 'puzzle icon',
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["services", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    puzzle: file(relativePath: { eq: "puzzle.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED)
      }
    }
  }
`;
