import React from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'gatsby';

import { METHODOLOGIE, REALISATIONS } from '@/referentials/routes';

import Accordion from '../../Accordion';
import LinkItem from '../../LinkItem';

interface QuestionsProps {
  className?: string;
}

const Questions: React.FC<QuestionsProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'flex flex-col lg:flex-row gap-12 lg:items-center justify-between',
        className,
      )}
    >
      <div
        className={classNames(
          'shadow-csm rounded-[40px]',
          'p-[30px]',
          'lg:w-7/12 lg:p-[5%] lg:pr-[3%]',
        )}
      >
        <h2>{t('questionsTitle')}</h2>
        <div>
          <Accordion title={t('q1Title')}>
            <Trans i18nKey="q1Content">
              <Link
                to={METHODOLOGIE}
                className="underline underline-offset-2"
              />
            </Trans>
          </Accordion>
          <Accordion title={t('q2Title')}>
            <Trans i18nKey="q2Content">
              <Link
                to={REALISATIONS}
                className="underline underline-offset-2"
              />
            </Trans>
          </Accordion>
          <Accordion title={t('q3Title')}>{t('q3Content')}</Accordion>
          <Accordion title={t('q4Title')}>{t('q4Content')}</Accordion>
          <Accordion title={t('q5Title')}>{t('q5Content')}</Accordion>
        </div>
      </div>
      <div
        className={classNames(
          'flex flex-col mt-12',
          'lg:mt-auto lg:w-[400px] 2xl:w-[500px] lg:m-auto',
        )}
      >
        <div className="text-base lg:text-2xl mb-4">
          {t('questionsSubtitle')}
        </div>
        <LinkItem
          className="font-heading text-xl lg:text-4xl"
          title={t('questionsLink')}
          to={METHODOLOGIE}
        />
      </div>
    </div>
  );
};

export default Questions;
